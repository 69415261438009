<template>
  <div class="daot">
    <h5header :name="$t('daot.desc2')" :right="false" />
    <div class="daot-wrap">
      <div class="dw-top">
        <div class="dwt-l">
          <div class="title"><span>DAOT</span> <span>{{ $t('daot.desc14') }}</span></div>
          <div class="til">{{ $t('daot.desc3') }}</div>
        </div>
        <div class="dwt-r">
          <div class="box">
            <div class="title">{{ $t('daot.desc4') }}</div>
            <div class="time-down">
              <van-count-down :time="time" @finish="finishTime">
                <template #default="timeData">
                  <div class="block">{{ dealTime(timeData.days) }}<span>{{ $t('daot.desc5') }}</span></div>
                  <div class="colon">:</div>
                  <div class="block">{{ dealTime(timeData.hours) }}<span>{{ $t('daot.desc6') }}</span></div>
                  <div class="colon">:</div>
                  <div class="block">{{ dealTime(timeData.minutes) }}<span>{{ $t('daot.desc7') }}</span></div>
                  <div class="colon">:</div>
                  <div class="block">{{ dealTime(timeData.seconds) }}<span>{{ $t('daot.desc8') }}</span></div>
                </template>
              </van-count-down>
            </div>
            <div class="ex">
              <div class="ex-item">
                <div class="text">
                  <img src="../../public/images/daot/num-icon.png" alt="">
                  {{ $t('daot.desc9') }}
                </div>
                <div class="num">1 DAOT={{ daotConfig.ratioDu }} DU {{ $t('daot.desc44') }} {{ daotConfig.ratioRc }} RC</div>
              </div>
              <div class="ex-item">
                <div class="text">
                  <img src="../../public/images/daot/num-icon.png" alt="">
                  {{ $t('daot.desc10') }}
                </div>
                <div class="num">{{ daotConfig.datoAmount }} DAOT</div>
              </div>
              <div class="ex-item">
                <div class="text">
                  <img src="../../public/images/daot/num-icon.png" alt="">
                  {{ $t('daot.desc11') }}
                </div>
                <div class="num">{{ $t('daot.desc15') }}</div>
              </div>
            </div>
            <div class="percent">{{ common.cutXiaoNum1(percentStr)+'%' }}（{{ daotConfig.datoAmount }} DAOT）</div>
            <div class="percent-line">
              <div class="line" :style="{ width: percentStr+'%' }"><span></span></div>
            </div>
            <div class="sale">{{ $t('daot.desc12') }}<span>{{ daotConfig.datoAmount - daotConfig.residueAmount }} / {{
              daotConfig.datoAmount }} DAOT</span></div>
            <div class="sale">ID<span>{{ userInfo.userId }}</span></div>
          </div>
          <div class="bt-out">
            <span>{{ $t('daot.desc13') }}</span>
            <div v-if="!isStartActivity" class="bt bt-no">{{ $t('daot.desc70') }}</div>
            <div v-else-if="isEndActivity" class="bt bt-no">{{ $t('daot.desc71') }}</div>
            <div v-else-if="daotConfig.residueAmount == 0" class="bt bt-no">{{ $t('daot.desc72') }}</div>
            <div v-else-if="!canBuy" class="bt bt-no">{{ $t('daot.desc14') }}</div>
            <div v-else class="bt" @click="startAsset(2)">{{ $t('daot.desc14') }}</div>
          </div>
          <div v-if="!canBuy" class="bt-out-tips">{{ $t('daot.desc69') }}</div>
        </div>
      </div>
      <div class="dw-ex">
        <div class="item">
          <div class="title">{{ $t('daot.desc16') }}</div>
          <div class="til">{{ daotConfig.buyAmount }} DAOT</div>
        </div>
        <div class="item item-pc">
          <div class="title">{{ $t('daot.desc17') }}</div>
          <div class="til">{{ dealAddress(daotConfig.buyAddress) || '--' }}
            <img v-if="daotConfig.buyAddress" @click="copy($event, daotConfig.buyAddress)"
              src="../../public/images/daot/copy.png" alt=""></div>
          <div class="bt grey" v-if="daotConfig.buyAmount == 0">{{ $t('daot.desc22') }}</div>
          <div class="bt" @click="openEdit()" v-else>{{ $t('daot.desc22') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc18') }}</div>
          <div class="til">{{ daotConfig.rcAmount }} RC</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc19') }}</div>
          <div class="til">{{ daotConfig.duAmount }} DU</div>
        </div>
        <div class="item item-no-border">
          <div class="title">{{ $t('daot.desc20') }}</div>
          <div class="til">{{ $t('daot.desc21') }}</div>
        </div>
        <div class="item item-h5">
          <div class="title">{{ $t('daot.desc17') }}</div>
          <div class="til">{{ dealAddress(daotConfig.buyAddress) || '--' }}
            <img v-if="daotConfig.buyAddress" @click="copy($event, daotConfig.buyAddress)"
              src="../../public/images/daot/copy.png" alt=""></div>
          <div class="bt" @click="openEdit()">{{ $t('daot.desc22') }}</div>
        </div>
      </div>
      <div class="dw-info">
        <div class="dwi-l">
          <img class="du" src="../../public/images/daot/DU.png" alt="">
          <div class="dwi-ex">
            <div class="item">
              <div class="name">{{ $t('daot.desc23') }}</div>
              <div class="text">DAOT</div>
            </div>
            <div class="item">
              <div class="name">{{ $t('daot.desc24') }}</div>
              <div class="text">{{ daotConfig.datoAmount }} DAOT</div>
            </div>
            <div class="item">
              <div class="name">{{ $t('daot.desc25') }}</div>
              <div class="text">Solana V2</div>
            </div>
            <div class="item">
              <div class="name">{{ $t('daot.desc26') }}</div>
              <div class="text green">{{ dealAddress(contractAddress) }}<img @click="copy($event, contractAddress)"
                  src="../../public/images/daot/copy.png" alt=""></div>
            </div>
          </div>
          <div class="p">{{ $t('daot.desc73') }}</div>
          <div class="list">
            <div class="li bird" @click="openUrl('https://twitter.com/Daoversal')"><img src="../../public/images/daot/bird.png" alt=""></div>
            <div class="li facebook" @click="openUrl('https://www.facebook.com/daoversal')"><img src="../../public/images/daot/facebook.png" alt=""></div>
            <div class="li telegram" @click="openUrl('https://t.me/wearedaoversal')"><img src="../../public/images/daot/telegram.png" alt=""></div>
          </div>
        </div>
        <img class="dwi-r dwi-r-pc" src="../../public/images/daot/info-logo.png" alt="">
        <img class="dwi-r dwi-r-h5" src="../../public/images/daot/info-logo-h5.png" alt="">
      </div>
      <div class="dw-ques">
        <div class="dwq-title">
          <img src="../../public/images/daot/ques-l.png" alt="">
          <span>{{ $t('daot.desc27') }}</span>
          <img src="../../public/images/daot/ques-r.png" alt="">
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc54') }}</div>
          <div class="til">{{ $t('daot.desc56') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc57') }}</div>
          <div class="til">{{ $t('daot.desc58') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc59') }}</div>
          <div class="til">{{ $t('daot.desc60') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc61') }}</div>
          <div class="til">{{ $t('daot.desc62') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc63') }}</div>
          <div class="til">{{ $t('daot.desc64') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc65') }}</div>
          <div class="til">{{ $t('daot.desc66') }}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('daot.desc67') }}</div>
          <div class="til">{{ $t('daot.desc68') }}</div>
        </div>
      </div>
    </div>
    <!-- 编辑地址 -->
    <el-dialog v-model="showEdit" :append-to-body="true">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showEdit = false" />
        <div class="modal-title">{{ $t("daot.desc40") }}</div>
        <div class="mc-box">
          <div class="mc-title">{{ $t("daot.desc41") }}</div>
          <input class="mc-inp" v-model="editAddress" type="text" :placeholder="$t('daot.desc42')">
        </div>
        <div class="modal-btncon flexcenter" @click="handlerEdit" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
      </div>
    </el-dialog>
    <!-- 认购 -->
    <el-dialog v-model="showSbu" :append-to-body="true">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showSbu = false" />
        <div class="modal-title">{{ $t("daot.desc14") }}</div>
        <div class="mc-box">
          <div class="mc-title">{{ $t("daot.desc28") }}</div>
          <div class="mc-ex">
            <div class="item" :class="{ active: mcIndex == 2 }" @click="getAsset(2)">{{ $t("daot.desc29") }}</div>
            <div class="item" :class="{ active: mcIndex == 1 }" @click="getAsset(1)">{{ $t("daot.desc30") }}</div>
          </div>
        </div>
        <div class="mc-box">
          <div class="mc-title">{{ $t("daot.desc31") }}</div>
          <el-select v-model="coinName" :placeholder="$t('daot.desc31')" @change="selectCoin">
            <el-option :label="item.coinName" :value="item.coinId" v-for="(item, i) in coinList" :key="i"></el-option>
          </el-select>
        </div>
        <div class="mc-box">
          <div class="mc-title">{{ $t("daot.desc32") }}</div>
          <input class="mc-inp" v-model="userBuyAmount" type="text" :placeholder="$t('daot.desc33')">
          <div class="mc-tips">{{ $t("daot.desc38") }} <span>{{ common.cutXiaoNum2(balance) }}</span></div>
        </div>
        <div class="mc-box" v-if="daotConfig.buyAddress == ''">
          <div class="mc-title">{{ $t("daot.desc34") }}</div>
          <input class="mc-inp" v-model="address" type="text" :placeholder="$t('daot.desc35')">
        </div>
        <div class="mc-line">
          <span>{{ $t("daot.desc36") }}</span>
          <span v-if="coinId == 30">1 DAOT= {{ daotConfig.ratioDu }} {{ coinName }}</span>
          <span v-if="coinId == 34">1 DAOT= {{ daotConfig.ratioRc }} {{ coinName }}</span>
        </div>
        <div class="mc-line">
          <span>{{ $t("daot.desc37") }}</span>
          <span>{{ common.cutXiaoNum1(receiveNum) }} DAOT</span>
        </div>
        <div class="modal-btncon flexcenter" @click="handlerSubmit" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
      </div>
    </el-dialog>
    <!-- 交易密码 -->
    <el-dialog v-model="showpsd" :append-to-body="true">
      <div class="modal-con">
        <img src="../../public/images/new/closeicon.png" alt="" class="close" @click="showpsd = false" />
        <div class="modal-title">{{ $t("home.desc7") }}</div>
        <div class="modal-desc">{{ $t("node.desc53") }}</div>
        <div class="modal-input">
          <psdinput @finish="finish" @finish1="finish1" ref="PsdInput" v-if="showpsd" />
        </div>
        <!-- <div class="modal-tips" v-if="(userInfo.hasUpdateTranPwd && userInfo.sourceType == 1) ||
                    (userInfo.hasUpdateTranPwd && userInfo.sourceType == 2)
                    ">
                    {{ $t("node.desc75") }}
                </div> -->
        <div class="modal-btncon flexcenter" @click="handlerPay" v-loading="loading">
          {{ $t("home.desc20") }}
        </div>
        <div class="forgottitle" @click="handlerLogpsd">{{ $t('sign.desc72') }}</div>
      </div>
    </el-dialog>
    <daotBuyPopup v-model:show="showBuyPopup" @confirm="getAsset(2)"/>
  </div>
</template>
<script>
import Clipboard from '@/utils/libs/clipboard'
// import gettime from '@/utils/common'
import psdinput from '@/componets/psdinput.vue'
import bus from "@/utils/bus";
import h5header from '@/componets/h5header.vue';
export default {
  components: {
    psdinput,
    h5header
  },
  data() {
    return {
      time: 0,
      percentStr: '0.00%',
      contractAddress: '--',
      loading: false,
      showpsd: false,
      showSbu: false,
      transactionPassword: '',
      coinList: [],
      coinId: 30, // 30 DU; 34 RC
      coinName: 'ru',
      mcIndex: 2,
      balance: 0.0000,
      daotConfig: {},
      address: '', // 认购地址
      userBuyAmount: '',
      userInfo: '',
      showEdit: false,
      editAddress: '', // 编辑地址
      showBuyPopup: false,
      isHasChoose: false,
      isBuy: false,
    }
  },
  computed: {
    receiveNum() {
      if (this.coinId == 30) {
        return this.userBuyAmount / this.daotConfig.ratioDu
      } else if (this.coinId == 34) {
        return this.userBuyAmount / this.daotConfig.ratioRc
      } else {return 0}
    },
    canBuy() {
      return (this.isHasChoose && this.isBuy) || !this.isHasChoose
    },
    isStartActivity() {
      if (this.daotConfig && this.daotConfig.datoAmount) {
        let config = this.daotConfig
        let nowTimes = this.gettime().getTime() - 4*3600*1000
        let startTimes = new Date(config.startTime.replace(/-/g,'/')).getTime()
        return nowTimes > startTimes
      } else {return false}
    },
    isEndActivity() {
      if (this.daotConfig && this.daotConfig.datoAmount) {
        let config = this.daotConfig
        let nowTimes = this.gettime().getTime() - 4*3600*1000
        let endTimes = new Date(config.endTime.replace(/-/g,'/')).getTime()
        return nowTimes > endTimes
      } else {return true}
    },
  },
  mounted() {
    this.getDaotConfig()
    this.getDaotState()
    this.$post(this.URL.member.info, {}).then((res) => {
      if (res.code == 0) {
        this.userInfo = res.data;
      }
    });
  },
  methods: {
    getDaotState() {
      this.$post(this.URL.daot.state).then((res) => {
        if (res.code == 0) {
          if (res.data == null) {
            this.showBuyPopup = true
          } else {
            this.isHasChoose = true
            this.isBuy = res.data.isBuy
          }
        }
      });
    },
    getDaotConfig() {
      this.$post(this.URL.daot.config, {}).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.daotConfig = res.data
            this.address = res.data.buyAddress
            let percentNum = (res.data.datoAmount - res.data.residueAmount) / res.data.datoAmount
            this.percentStr = percentNum * 100
            this.dealCountdown()
          }
        }
      })
    },
    startAsset(type) {
      if (!this.isHasChoose) {
        this.showBuyPopup = true
      } else {
        this.getAsset(type)
      }
    },
    getAsset(type) { // 2 奖金账户 1 投资账户
      this.mcIndex = type
      this.$post(this.URL.assets.info, {
        accountType: this.mcIndex,
      }).then((res) => {
        if (res.code == 0) {
          this.coinList = this.dealList(res.data.list);
          this.selectCoin(this.coinList[0].coinId)
          this.showSbu = true
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dealList(list) {
      let newList = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].coinId == 30 || list[i].coinId == 34) {
          newList.push(list[i])
        }
      }
      return newList
    },
    dealCountdown() {
      let startT = this.daotConfig.startTime.replace(/-/g, '/')
      let endT = this.daotConfig.endTime.replace(/-/g, '/')
      // let nTim = new Date().getTime()
      let nTim = new Date(this.gettime()).getTime() - 4*3600*1000
      let sTim = new Date(startT).getTime()
      let eTim = new Date(endT).getTime()
      this.time = 0
      if (nTim > sTim) { // 活动开始了
        if (eTim > nTim) {
          this.time = eTim - nTim
        }
      }
    },
    handlerSubmit() {
      if (this.userBuyAmount == '') {
        this.$message.error(this.$t('daot.desc33'));
        return;
      }
      if (this.address == '') {
        this.$message.error(this.$t('daot.desc35'));
        return;
      }
      this.showSbu = false
      this.showpsd = true
    },
    handlerPay() {
      if (this.transactionPassword == "") {
        this.$message.error(this.$t("planet.desc17"));
        return;
      }
      if (!this.loading) {
        this.loading = true;
        this.$post(this.URL.daot.buy, {
          buyAmount: this.userBuyAmount,
          coinId: this.coinId,
          address: this.address,
          accountType: this.mcIndex,
          password: this.userInfo.sourceType == 2 || this.userInfo.sourceType == 3
            ? this.transactionPassword
            : this.common.mdpassword(this.transactionPassword),
        }).then(res => {
          if (res.code == 0) {
            this.loading = false;
            this.$message.success(this.$t('daot.desc39'));
            this.showpsd = false
            this.getDaotConfig()
            this.transactionPassword = ""
            this.userBuyAmount = ""
            this.address = ""
            this.mcIndex = 2
          } else {
            this.loading = false;
            this.transactionPassword = "";
            this.$refs.PsdInput.reset();
            this.$message.error(res.message)
          }
        })
      }
    },
    openEdit() {
      this.showEdit = true
      this.editAddress = ''
    },
    handlerEdit() {
      if (this.editAddress == "") {
        this.$message.error(this.$t("daot.desc42"));
        return;
      }
      if (!this.loading) {
        this.loading = true;
        this.$post(this.URL.daot.updateAddress, {
          address: this.editAddress,
        }).then(res => {
          if (res.code == 0) {
            this.loading = false;
            this.$message.success(this.$t('daot.desc43'));
            this.showEdit = false
            this.getDaotConfig()
            this.editAddress = ""
          } else {
            this.loading = false;
            this.editAddress = "";
            this.$message.error(res.message)
          }
        })
      }
    },
    handlerLogpsd() {
      this.showpsd = false;
      if (localStorage.getItem('ismobile') == 1) {
        this.$router.push('/user/info?id=2')
      } else {
        bus.emit("hadleropen", 1);
      }
    },
    selectCoin(id) { // 根据类型查询
      this.coinId = id
      for (let i = 0; i < this.coinList.length; i++) {
        const element = this.coinList[i];
        if (element.coinId == id) {
          this.coinName = element.coinName
          this.balance = element.amount - element.frozenAmount // 总数减去冻结=可用
          break;
        }
      }
    },
    finish(val) {
      this.transactionPassword = val;
    },
    finish1(val) {
      if (val == 0) {
        this.transactionPassword = "";
      }
    },
    finishTime() {
      this.getDaotConfig()
      this.getDaotState()
    },
    dealTime(num) {
      let str = num + ''
      if (str && str.length == 1) {
        return '0' + num
      } else { return num }
    },
    dealAddress(str) {
      if (str && str.length > 12) {
        return str.slice(0, 7) + '***' + str.slice(-5)
      } else {
        return str
      }
    },
    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t('planet.desc61'))
      });
    },
    openUrl(url) {
      if (url) window.open(url)
    },
    gettime() {
      return new Date(
        new Date().getTime() +
        (parseInt(new Date().getTimezoneOffset() / 60) + 8) * 3600 * 1000
      );
    },
  }
}
</script>
<style>
.el-overlay {
  background: rgba(0, 0, 0, 0.50) !important;
  backdrop-filter: blur(12.5px) !important;
}
.el-dialog__body,.el-dialog {
  background: transparent !important;
}
</style>
<style lang="less" scoped>
.el-overlay {
  background: rgba(0, 0, 0, 0.50) !important;
  backdrop-filter: blur(12.5px);
}
.modal-con {
  position: relative;
  padding: 32px;
  width: 480px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) -1.52%, rgba(255, 255, 255, 0.02) 104.35%);
  backdrop-filter: blur(60px);
  border-radius: 12px;
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .mc-box {
    margin-bottom: 24px;

    .mc-title {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
    }

    .mc-ex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      .item {
        flex: 1;
        height: 39px;
        border-radius: 4px;
        background: #242424;
        color: rgba(255, 255, 255, 0.60);
        text-align: center;
        font-family: "Bai Jamjuree";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 39px;
        cursor: pointer;

        &.active {
          color: #000;
          background: #C9FA5B;
        }
      }
    }

    .mc-inp {
      border-radius: 4px;
      border: 1px solid #393939;
      width: 100%;
      height: 50px;
      padding: 0 16px;
      color: #fff;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &::placeholder {
        color: rgba(255, 255, 255, 0.40);
        font-family: "Bai Jamjuree";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .mc-tips {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: rgba(255, 255, 255, 0.60);
      font-family: "Bai Jamjuree";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: #C9FA5B;
      }
    }
  }

  .mc-line {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: block;
      color: rgba(255, 255, 255, 0.60);
      font-family: "Bai Jamjuree";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:last-child {
        color: #FFF;
        font-family: "Bai Jamjuree";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .modal-title {
    margin-bottom: 32px;
    font-size: 20px;
    color: #ffffff;
  }

  .modal-desc {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .modal-input {
    margin: 20px 0 16px;
  }

  .modal-tips {
    font-size: 14px;
    color: #eb4343;
  }

  .modal-btncon {
    margin-top: 32px;
    height: 50px;
    background: url("../../public/images/new/btnbg16.png") center no-repeat;
    background-size: 100% 100%;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
  }

  .forgottitle {
    margin-top: 24px;
    color: #c9fa5b;
    font-size: 16px;
    cursor: pointer;
    text-align: right;
  }

  .modal-btn {
    margin-top: 32px;
    justify-content: space-between;

    div {
      flex: 0 0 197px;
      height: 50px;
      background: url("../../public/images/new/btnbg14.png") center no-repeat;
      background-size: 100% 100%;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;

      &:last-child {
        background: url("../../public/images/new/btnbg15.png") center no-repeat;
        background-size: 100% 100%;
        color: #000000;
      }
    }
  }
}

:deep(.el-select) {
  width: 100%;

  .el-select__caret.el-icon {
    margin: 0;
  }

  .el-input__wrapper {
    background-color: transparent;
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid #393939;
    height: 50px;
    padding: 0 16px;

    .el-input__inner {
      color: #fff;
      font-family: PingFang SC;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      &::placeholder {
        color: #fff;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &.is-focus {
      box-shadow: none !important;
      border: 1px solid #393939;
      outline: none !important;
      border-radius: 8px;
    }
  }
}

.daot {
  padding: 96px 0;
  min-height: 100vh;
  background: #000 url('../../public/images/daot/bg.png') no-repeat;
  background-size: 100% auto;

  .daot-wrap {
    width: 1200px;
    margin: 0 auto;
    padding-top: 72px;
  }
}

.dw-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 33px;

  .dwt-l {
    flex: 1;

    .title {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      span {
        display: inline-block;
        margin-right: 10px;
        color: #FFF;
        font-family: "FONTSPRING DEMO - Integral CF Bold";
        font-size: 81px;
        font-style: normal;
        font-weight: 700;
        line-height: 66px; /* 81.481% */
        vertical-align: bottom;
        &:last-child {
          display: inline-block;
          vertical-align: bottom;
          color: #FFF;
          font-family: "Bai Jamjuree";
          font-size: 61px;
          font-style: normal;
          font-weight: 500;
          line-height: 70px; /* 91.667% */
          text-transform: uppercase;
        }
      }
      margin-bottom: 40px;
    }

    .til {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 100% */
    }
  }

  .dwt-r {
    width: 746px;
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 0.28);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) -1.52%, rgba(255, 255, 255, 0.02) 104.35%);
    backdrop-filter: blur(60px);

    .box {
      padding: 24px;

      .title {
        color: #C9FA5B;
        font-family: "Bai Jamjuree";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        line-height: 20px;
        /* 100% */
        margin-bottom: 24px;
      }

      .time-down {
        margin-bottom: 57px;
        :deep(.van-count-down) {
          display: flex;
          justify-content: center;
          align-items: center;

          .block {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.10);
            min-width: 66px;
            height: 66px;
            padding: 0 6px;
            color: #fff;
            text-align: center;
            font-family: "Bai Jamjuree";
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            position: relative;
            span {
              display: block;
              position: absolute;
              bottom: -30px;
              left: 0;
              width: 100%;
              color: #FFF;
              text-align: center;
              font-family: "Bai Jamjuree";
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              text-transform: capitalize;
              white-space: nowrap;
            }
          }

          .colon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 66px;
            color: #FFF;
            text-align: center;
            font-family: "Bai Jamjuree";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 66px;
            /* 550% */
            text-transform: uppercase;
          }
        }
      }

      .ex {
        display: flex;
        margin-bottom: 16px;

        .ex-item {
          flex: 1;

          .text {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-family: "Bai Jamjuree";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            /* 142.857% */
            img {
              width: 10px;
              height: 10px;
              margin-right: 4px;
            }
          }

          .num {
            margin-top: 12px;
            color: #C9FA5B;
            text-align: center;
            font-family: "Bai Jamjuree";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
          }

          &:first-child {

            .text,
            .num {
              text-align: left;
              justify-content: flex-start;
            }
          }

          &:last-child {

            .text,
            .num {
              text-align: right;
              justify-content: flex-end;
            }
          }
        }
      }

      .percent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 26px;
        margin: 0 auto 8px;
        border-radius: 8px;
        background: #FFF;
        padding: 4px;
        color: #040603;
        text-align: center;
        font-family: "Bai Jamjuree";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .percent-line {
        background: #fff;
        width: 100%;
        height: 12px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 8px;

        .line {
          display: block;
          width: 100%;
          height: 12px;
          // background: #C9FA5C;
          border-radius: 12px;
          background: linear-gradient(295deg, #E9FDBE 9.05%, #C9FA5C 79.28%);
          position: relative;

          // span {
          //   position: absolute;
          //   right: 0;
          //   top: 0;
          //   display: block;
          //   width: 200%;
          //   height: 100%;
          //   background: url('../../public/images/daot/line-bg.png');
          //   background-size: auto 100%;
          //   // transition: all 1s ease-in;
          //   animation: ball-turn 10s linear infinite;

          //   @keyframes ball-turn {
          //     0% {
          //       right: 0;
          //     }

          //     100% {
          //       right: -90%;
          //     }
          //   }
          // }
        }
      }

      .sale {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.60);
        font-family: "Bai Jamjuree";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;

        /* 100% */
        span {
          color: #FFF;
          text-align: right;
          font-family: "Bai Jamjuree";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .bt-out {
      height: 67px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid rgba(255, 255, 255, 0.28);
      padding: 0 14px;
      span {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        color: rgba(255, 255, 255, 0.80);
        font-family: "Bai Jamjuree";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        margin-right: 40px;
        position: relative;
      }

      .bt {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 148.612px;
        height: 47.896px;
        padding-left: 10px;
        background: url('../../public/images/daot/bt-bg.png') no-repeat;
        background-size: 100% 100%;
        color: #040603;
        text-align: center;
        font-family: "Bai Jamjuree";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        cursor: pointer;
        &-no {
          color: #040603;
          background: url('../../public/images/daot/bt-bg-no.png') no-repeat;
          background-size: 100% 100%;
          cursor: not-allowed;
        }
      }
    }
    .bt-out-tips {
      width: 100%;
      padding: 12px 0;
      color: #EB4343;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      text-align: center;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.dw-ex {
  margin-bottom: 59px;
  display: flex;
  padding: 16px 0;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.10) -1.52%, rgba(255, 255, 255, 0.02) 104.35%);
  backdrop-filter: blur(7px);
  border-radius: 16px;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
    padding: 0 24px;
    border-right: 1px solid rgba(255, 255, 255, 0.20);
    &.item-pc {display: flex;}
    &.item-h5 {display: none;}
    &.item-no-border {border-right: none;}
    &:last-child {
      border-right: none;
    }

    .title {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }

    .til {
      display: flex;
      align-items: center;
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      img {
        width: 12px;
        height: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .bt {
      width: fit-content;
      min-width: 92px;
      height: 32px;
      padding: 0 10px;
      margin-top: 8px;
      background: url('../../public/images/daot/edit-bt.png') no-repeat;
      background-size: 100% 100%;
      color: #000;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      /* 114.286% */
      cursor: pointer;
    }
  }
}

.dw-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;
  position: relative;
  .dwi-l {
    width: 704px;

    .du {
      display: block;
      width: 60px;
      height: 60px;
      margin-bottom: 24px;
    }

    .dwi-ex {
      display: flex;
      gap: 48px;
      margin-bottom: 24px;

      .item {
        flex: 1;

        .name {
          color: rgba(255, 255, 255, 0.60);
          font-family: "Bai Jamjuree";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 12px;
          white-space: nowrap;
        }

        .text {
          display: flex;
          align-items: center;
          color: #fff;
          font-family: "Bai Jamjuree";
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          white-space: nowrap;

          &.green {
            color: #C9FA5B;
          }

          img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
          }
        }
      }
    }

    .p {
      color: rgba(255, 255, 255, 0.60);
      font-family: "Bai Jamjuree";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 24px;
    }

    .list {
      display: flex;
      align-items: center;
      gap: 24px;

      .li {
        width: 34px;
        height: 34px;
        border-radius: 34px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          width: 16px;
          height: auto;
          display: block;
        }

        &.facebook {
          img {
            width: 13px;
          }
        }
      }
    }
  }

  .dwi-r {
    // position: absolute;
    // top: -50px;
    // right: -95px;
    // width: 790px;
    // height: 557px;
    flex: 1;
    height: auto;
    display: block;
    // margin-left: 48px;
    &.dwi-r-pc {display: block;}
    &.dwi-r-h5 {display: none;}
  }
}

.dw-ques {
  >div {
    margin-bottom: 32px;
  }

  .dwq-title {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #C9FA5B;
      text-shadow: 0px 0px 20px rgba(201, 250, 91, 0.40);
      font-family: "Bai Jamjuree";
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 40px;
      margin: 0 21px;
    }

    /* 100% */
    img {
      width: auto;
      height: 17px;
      display: block;
    }
  }

  .item {
    border: 1px solid rgba(255, 255, 255, 0.20);
    padding: 16px;

    .title {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 20px;
      text-align: left;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 100% */
      margin-bottom: 20px;
    }

    .til {
      color: rgba(255, 255, 255, 0.80);
      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        color: #C9FA5B;
      }
    }
  }
}
@media (max-width: 1200px) {
.modal-con {
  width: 100% !important;
  padding: 32px 16px;

  .modal-title {
    padding-right: 40px;
  }

  .close {
    right: 16px;
  }

  .modal-btn {
    div {
      flex: 0 0 49%;
      height: 44px;
    }
  }
}
.daot {
  padding: 50px 20px;
  min-height: 100vh;
  background: #000 url('../../public/images/daot/bg-h5.png') no-repeat;
  background-size: 100% auto;

  .daot-wrap {
    width: 100%;
    margin: 0 auto;
    padding-top: 44px;
  }
}

.dw-top {
  display: block;
  margin-bottom: 32px;

  .dwt-l {
    flex: none;
    width: 100%;
    margin-bottom: 32px;
    .title {
      color: #fff;
      justify-content: center;
      line-height: 40px;
      span {
        font-size: 32px;
        line-height: 30px;
        &:last-child {font-size: 32px;line-height: 30px;}
      }
      margin-bottom: 20px;
    }

    .til {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      line-height: 16px;
      /* 100% */
    }
  }

  .dwt-r {
    width: 100%;
    .box {
      padding: 16px;
      border: 1px solid rgba(255, 255, 255, 0.20);

      .title {
        color: #C9FA5B;
        font-family: "Bai Jamjuree";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        line-height: 18px;
        /* 100% */
        margin-bottom: 16px;
      }

      .time-down {
        margin-bottom: 40px;
        :deep(.van-count-down) {
          display: flex;
          justify-content: center;
          align-items: center;

          .block {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            min-width: 55px;
            height: 56px;
            text-align: center;
            font-family: "Bai Jamjuree";
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            span {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .colon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            flex: 1;
            height: 56px;
            color: #FFF;
            text-align: center;
            font-family: "Bai Jamjuree";
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 56px;
            /* 550% */
            text-transform: uppercase;
          }
        }
      }

      .ex {
        display: flex;
        margin-bottom: 16px;
        gap: 12px;
        .ex-item {
          flex: 1;

          .text {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-family: "Bai Jamjuree";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 13px;

            /* 142.857% */
            img {display: none;}
          }

          .num {
            margin-top: 8px;
            color: #C9FA5B;
            text-align: center;
            font-family: "Bai Jamjuree";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px;
          }

          &:first-child {

            .text,
            .num {
              text-align: left;
              justify-content: flex-start;
            }
          }

          &:last-child {

            .text,
            .num {
              text-align: right;
              justify-content: flex-end;
            }
          }
        }
      }

      .percent {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 21px;
        margin: 0 auto 8px;
        border-radius: 8px;
        background: #FFF;
        padding: 4px;
        color: #040603;
        text-align: center;
        font-family: "Bai Jamjuree";
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .percent-line {
        background: #fff;
        width: 100%;
        height: 12px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 8px;

        .line {
          display: block;
          width: 100%;
          height: 12px;
          position: relative;
        }
      }

      .sale {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(255, 255, 255, 0.60);
        font-family: "Bai Jamjuree";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;

        /* 100% */
        span {
          color: #FFF;
          text-align: right;
          font-family: "Bai Jamjuree";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }

    .bt-out {
      padding: 0 10px;
      height: 47px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        color: rgba(255, 255, 255, 0.80);
        font-family: "Bai Jamjuree";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 100% */
        margin-right: 0;
        position: relative;
      }

      .bt {
        margin-top: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        min-width: 87.2px;
        height: 36.8px;
        padding: 0 8px 0 18px;
        background: url('../../public/images/daot/bt-bg-h5.png') no-repeat;
        background-size: 100% 100%;
        color: #040603;
        text-align: center;
        font-family: "Bai Jamjuree";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 36.8px;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
        &-no {
          color: #040603;
          background: url('../../public/images/daot/bt-bg-no-h5.png') no-repeat;
          background-size: 100% 100%;
          cursor: not-allowed;
        }
      }
    }
    .bt-out-tips {
      font-size: 12px;
      padding: 6px 0;
    }
  }
}

.dw-ex {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: transparent;
  backdrop-filter: blur(7px);

  .item {
    display: block;
    width: 50%;
    padding: 18px 0;
    border: none;
    margin-bottom: 16px;
    &.item-pc {display: none;}
    &.item-h5 {display: block;}
    &:nth-child(3),
    &:nth-child(5) {
      padding-left: 16px;
      border-left: 1px solid rgba(255, 255, 255, 0.20);
    }
    &:last-child {
      width: 100%;
      padding: 0;
      border-right: none;
      margin-bottom: 0;
      position: relative;
      .bt {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .title {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }

    .til {
      display: flex;
      align-items: center;
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      img {
        width: 12px;
        height: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .bt {
      width: fit-content;
      min-width: 92px;
      height: 32px;
      padding: 0 10px;
      margin-top: 8px;
      background: url('../../public/images/daot/edit-bt.png') no-repeat;
      background-size: 100% 100%;
      color: #000;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      /* 114.286% */
      cursor: pointer;
    }
  }
}

.dw-info {
  display: block;
  margin-bottom: 32px;

  .dwi-l {
    flex: none;
    width: 100%;
    .du {
      display: block;
      width: 48px;
      height: 48px;
      margin-bottom: 16px;
    }

    .dwi-ex {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      margin-bottom: 0;

      .item {
        flex: none;
        width: 50%;
        margin-bottom: 16px;
        .name {
          color: rgba(255, 255, 255, 0.60);
          font-family: "Bai Jamjuree";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 8px;
          white-space: nowrap;
        }

        .text {
          display: flex;
          align-items: center;
          color: #fff;
          font-family: "Bai Jamjuree";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          white-space: nowrap;

          &.green {
            color: #C9FA5B;
          }

          img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
          }
        }
      }
    }

    .p {
      color: rgba(255, 255, 255, 0.60);
      font-family: "Bai Jamjuree";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.12) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .list {
      display: flex;
      align-items: center;
      gap: 24px;

      .li {
        width: 34px;
        height: 34px;
        border-radius: 34px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16px;
          height: auto;
          display: block;
        }

        &.facebook {
          img {
            width: 13px;
          }
        }
      }
    }
  }

  .dwi-r {
    display: block;
    width: 100%;
    height: auto;
    margin-left: 0;
    &.dwi-r-pc {display: none;}
    &.dwi-r-h5 {display: block;}
  }
}

.dw-ques {
  >div {
    margin-bottom: 16px;
  }

  .dwq-title {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #C9FA5B;
      text-shadow: 0px 0px 20px rgba(201, 250, 91, 0.40);
      font-family: "Bai Jamjuree";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin: 0 21px;
    }

    /* 100% */
    img {
      width: auto;
      height: 17px;
      display: block;
    }
  }

  .item {
    border: 1px solid rgba(255, 255, 255, 0.20);
    padding: 16px;

    .title {
      color: #FFF;
      font-family: "Bai Jamjuree";
      font-size: 14px;
      text-align: left;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 100% */
      margin-bottom: 16px;
    }

    .til {
      color: rgba(255, 255, 255, 0.80);
      font-family: "Bai Jamjuree";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;

      span {
        color: #C9FA5B;
      }
    }
  }
}
}
</style>